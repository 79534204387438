import { Button, Drawer } from "@mui/material";
import React, {  useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./BottomDrawer.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MyAccordian from "./My_Accordian";

function BottomDrawer({ open, setOpen, selectedCourseDetails }) {
  // const dispatch = useDispatch();

  const [showPlay, setShowPlay] = useState(false);
  const videoRef = useRef(null);
  const topRef = useRef(null);
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift" || event.key === "Escape")
    ) {
      return;
    }

    setOpen(!open);
    setOpenedAccordian([]);
    topRef.current.scrollIntoView();
  };

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused === true) {
      videoElement.play();
      setShowPlay(true);
    } else {
      videoElement.pause();
      setShowPlay(false);
    }
  };

  const getPreviewVideoPlayer = (preview_video_url) => {
    return (
      <div className="bottomDrawer_videoPlayer">
        {showPlay === false ? (
          <div className="getPreviewVideoPlayer_play_button">
            <PlayCircleIcon style={{ color: "#FF3131", fontSize: "65px" }} />
          </div>
        ) : (
          <div className="getPreviewVideoPlayer_pause_button">
            <PauseCircleIcon style={{ color: "#FF3131", fontSize: "65px" }} />
          </div>
        )}
        {
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              cursor: "pointer",
              zIndex: "200",
            }}
            onClick={() => {
              if (videoRef.current.webkitRequestFullscreen) {
                videoRef.current.webkitRequestFullscreen();
              }
            }}
          >
            <FullscreenIcon style={{ fontSize: "30px" }} />
          </div>
        }
        <video
          src={
            preview_video_url ??
            "https://res.cloudinary.com/cqn/video/upload/v1709572848/AceTech-Business-Carousal_pllyr1.mp4"
          }
          ref={videoRef}
          onClick={handleVideoClick}
          playsInline
        />
      </div>
    );
  };

  const getBookNow = () => {
    return (
      <div className="bottomDrawer_bookNow">
        <div
          style={{ display: "flex", alignItems: "start", marginRight: "20px" }}
        >
          <div>
            <h3
              style={{
                margin: "0px",
                fontSize: "30px",
                fontFamily: "sans-serif",
              }}
            >
              ₹{selectedCourseDetails?.currPrice}
            </h3>
            <span
              style={{
                fontSize: "18px",
                margin: "0px",
                textDecoration: "line-through",
                color: "#888",
                fontFamily: "sans-serif",
              }}
            >
              ₹{selectedCourseDetails?.originalPrice}
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#f73535dc",
              padding: "2px 10px",
              borderRadius: "15px",
              marginLeft: "10px",
            }}
          >
            <span
              style={{
                fontFamily: "sans-serif",
                fontSize: "12px",
                color: "#fff",
              }}
            >
              -73%
            </span>
          </div>
        </div>
        <Button>
          <div style={{ display: "flex" }}>
            <LockOpenIcon />
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginLeft: "10px",
              }}
            >
              Unlock Now
            </span>
          </div>
        </Button>
      </div>
    );
  };
  const [openedAccordian, setOpenedAccordian] = useState([]);
  const getVideoDetails = () => {
    return (
      <div style={{ padding: "10px 10px" }}>
        <div>
          <h2 style={{ fontSize: "24px", fontWeight: "800" }}>
            {selectedCourseDetails?.title}
          </h2>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "25px",
              wordSpacing: "3px",
            }}
          >
            {selectedCourseDetails?.subtitle}
          </p>
        </div>
        <div>
          <div>
            {getEachVideoDetails(
              <SpatialAudioOffIcon
                style={{ color: "#fff", fontSize: "18px" }}
              />,
              "Audio",
              "Hindi • English"
            )}

            {getEachVideoDetails(
              <AssignmentIcon style={{ color: "#fff", fontSize: "18px" }} />,
              "Certificate",
              "Certificate of Completion"
            )}
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <h3>This course Includes:</h3>
          {getThisCourseIncludes(
            <OndemandVideoIcon />,
            `${selectedCourseDetails?.numOfVideos} videos`
          )}
          {getThisCourseIncludes(
            <AccessTimeIcon />,
            `${selectedCourseDetails?.numOfHours} hours`
          )}
          {getThisCourseIncludes(
            <PhoneIphoneIcon />,
            "Access on Phone or Laptop"
          )}
        </div>
        <div style={{ marginTop: "50px" }}>
          <h3>Course Content:</h3>
          <MyAccordian
            courseDetails={selectedCourseDetails}
            setOpenedAccordian={setOpenedAccordian}
            openedAccordian={openedAccordian}
          />
        </div>
        <div style={{ marginTop: "50px" }}>
          <h3>Requirements:</h3>
          <ul className="bottomDrawer_requirements_list">
            <li>A computer with access to the internet.</li>
            <li>No paid software required.</li>
          </ul>
        </div>
      </div>
    );
  };

  const getThisCourseIncludes = (icon, text) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {icon}
        <p
          style={{
            fontSize: "18px",
            fontFamily: "sans-serif",
            margin: "0px 10px",
          }}
        >
          {text}
        </p>
      </div>
    );
  };

  const getEachVideoDetails = (icon, title, text) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 20px 20px 0px",
        }}
      >
        <div
          style={{
            backgroundColor: "#FF3131",
            padding: "8px 10px",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        >
          {icon}
        </div>
        <div>
          <span style={{ fontWeight: "500" }}>{title}</span>
          <p style={{ margin: "0px", fontWeight: "700" }}>{text}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            height: "100%",
            backgroundColor: "#fffcf3",
          },
        }}
        variant="persistent"
        anchor={"bottom"}
        open={open}
      >
        <div
          style={{ height: "100vh", display: "flex", flexDirection: "column" }}
        >
          <div className="bottomDrawer_main_container">
            <div
              className="bottomDrawer_topBar_title"
              style={{ display: "flex" }}
            >
              <h3>Courses {" > "}</h3>
              <h3>{selectedCourseDetails?.course_tag}</h3>
            </div>
            <div className="bottomDrawer_close_icon">
              <CloseIcon style={{ color: "#222" }} onClick={toggleDrawer()} />
            </div>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div ref={topRef}>
              {getPreviewVideoPlayer(selectedCourseDetails?.preview_video_url)}
            </div>
            <div>{getVideoDetails()}</div>
          </div>
          <div style={{ position: "relative" }}>{getBookNow()}</div>
        </div>
      </Drawer>
    </div>
  );
}

export default BottomDrawer;
