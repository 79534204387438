import {
  GET_SELECTED_COURSE_DETAILS_ERROR,
  GET_SELECTED_COURSE_DETAILS_PROGRESS,
  GET_SELECTED_COURSE_DETAILS_SUCCESS,
  GET_SUGGESTED_COURESES_FAIL,
  GET_SUGGESTED_COURESES_SUCCESS,
  GET_SUGGESTED_COURSES_PROGRESS,
  SELECTED_COURSE_FROM_LIST,
  SELECTED_EXPLORE_COURSE,
} from "../../constants/Education/coursesConstants";
import axios from "axios";
import { APIRequestURI } from "../../utils/api_request_uri";

export const addSelectedFromExploreAction = (value) => (dispatch) => {
  dispatch({ type: SELECTED_EXPLORE_COURSE, selectedCourse: value });
};

export const addSelectedCourseFromListAction =
  (value, myClickCategory) => (dispatch) => {
    dispatch({
      type: SELECTED_COURSE_FROM_LIST,
      selectedCourse: value,
      myClickCategory: myClickCategory,
    });
  };

export const getSelectedCourseDataAction = (value) => async (dispatch) => {
  try {
    dispatch({ type: GET_SELECTED_COURSE_DETAILS_PROGRESS });
    const storedData = JSON.parse(localStorage.getItem(`courses-${value}`));

    if (
      storedData === null ||
      storedData === undefined ||
      storedData.length === 0
    ) {
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.get(
        `${URI}/api/courses?course_tag=${value}`
      );

      dispatch({ type: GET_SELECTED_COURSE_DETAILS_SUCCESS, payload: data });
      let curr_storedData = JSON.stringify({
        date: Date.now(),
        data: data,
      });
      localStorage.setItem(`courses-${value}`, curr_storedData);
    } else {
      const currDate = Date.now();
      var diffInDate = currDate - storedData.date;
      diffInDate = diffInDate / (1000 * 60 * 60 * 24);
      diffInDate = diffInDate.toFixed();

      if (diffInDate >= 1) {
        dispatch({ type: GET_SELECTED_COURSE_DETAILS_PROGRESS });
        const URI = new APIRequestURI().getBaseURI();
        const { data } = await axios.get(`${URI}/api/courses?course_tag=${value}`);
        if (data.delta !== storedData.delta) {
          localStorage.removeItem(`courses-${value}`);

          dispatch({
            type: GET_SELECTED_COURSE_DETAILS_SUCCESS,
            payload: data,
          });
          let curr_storedData = JSON.stringify({
            date: Date.now(),
            data: data,
          });
          localStorage.setItem(`courses-${value}`, curr_storedData);
        }
      } else {
        dispatch({
          type: GET_SELECTED_COURSE_DETAILS_SUCCESS,
          payload: storedData.data,
        });
      }
    }
  } catch (error) {
    dispatch({ type: GET_SELECTED_COURSE_DETAILS_ERROR, error: error.message });
  }
};

export const getSuggestedCoursesAction =
  (suggestedCoursesList, value) => async (dispatch) => {
    try {
      dispatch({ type: GET_SUGGESTED_COURSES_PROGRESS });
      const storedData = JSON.parse(
        localStorage.getItem(`suggestedCourses-${value}`)
      );

      if (
        storedData === null ||
        storedData === undefined ||
        storedData.length === 0
      ) {
        const URI = new APIRequestURI().getBaseURI();
        const { data } = await axios.post(`${URI}/api/courses/suggestedcourses`, {
          suggestedCoursesList,
        });
        dispatch({ type: GET_SUGGESTED_COURESES_SUCCESS, payload: data });

        let curr_storedData = JSON.stringify({
          date: Date.now(),
          data: data,
        });
        localStorage.setItem(`suggestedCourses-${value}`, curr_storedData);
      } else {
        const currDate = Date.now();
        var diffInDate = currDate - storedData.date;
        diffInDate = diffInDate / (1000 * 60 * 60 * 24);
        diffInDate = diffInDate.toFixed();
        if (diffInDate >= 1) {
          dispatch({ type: GET_SUGGESTED_COURSES_PROGRESS });
          const URI = new APIRequestURI().getBaseURI();
          const { data } = await axios.post(`${URI}/api/courses/suggestedcourses`, {
            suggestedCoursesList,
          });
          if (data.delta !== storedData.delta) {
            localStorage.removeItem(`suggestedCourses-${value}`);
            dispatch({ type: GET_SUGGESTED_COURESES_SUCCESS, payload: data });

            let curr_storedData = JSON.stringify({
              date: Date.now(),
              data: data,
            });
            localStorage.setItem(`suggestedCourses-${value}`, curr_storedData);
          }
        } else {
          dispatch({
            type: GET_SUGGESTED_COURESES_SUCCESS,
            payload: storedData.data,
          });
        }
      }
    } catch (error) {
      dispatch({ type: GET_SUGGESTED_COURESES_FAIL, payload: error.message });
    }
  };
